.widgets {
    display: flex;
    height: 100%;
    align-items: center;
    transform: translateY(-20%);
}

@media (max-width: 1065px) {
    .widgets {
        height: auto;
        transform: none;
    }
    .title-block {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .footer {
        position: static;
    }
}

.widget-embedded {
    width: 350px;
    height: 200px;
    margin-right: 20px;
}

@media (max-width: 1065px) {
    .widget-embedded {
        height: 480px;
        margin-right: 0;
    }
    
}

.widget-embedded > div {
    width: 350px!important;
}

.widget-buttons {
    margin-top: 20px;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 14px;
    display: flex;
    transition: .3s all; 
    img {
        border-radius: 100%;
        height: 45px;
        width: 45px;
        margin-right: 5px;
        margin-left: 5px;
        transition: .3s all;
    }
    img:hover {
        -webkit-box-shadow: 0px 5px 10px 2px rgba(255, 255, 255, 0.2);
        -moz-box-shadow: 0px 5px 10px 2px rgba(255, 255, 255, 0.2);
        box-shadow: 0px 5px 10px 2px rgba(255, 255, 255, 0.2);
    }
    p {
        margin-left: 5px;
        margin-right: 25px;
    }
}

.widget-links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
    font-family: 'Montserrat';
    color: #fff;
    text-decoration: none;
    a {
        padding: 5px 10px;
        background-color: rgb(42, 52, 145);
        border-radius: 10px;
        color: #fff;
        text-decoration: none;
    }
    a:first-of-type {
        background-color: #1fc7d4;
    }
    a:hover {
        text-decoration: none;
        color: #fff;
    }
}

@media (max-width: 1064px) {
    .widgets {
        justify-content: center;
        flex-direction: column;
    }
}