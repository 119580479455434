.App {
  text-align: center;
  height: 100%;
}

.App > .container {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 1065px) {
  .main-container {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

$t: transparent;

.title-logo {
  position: relative;
  display: flex;
  top: -10%;
  align-items: center;
  img {
    width: 110px;
    height: 110px;
  }
  h1 {
    font-family: 'Montserrat';
    color: white;
    font-weight: 600;
    font-size: 68px;
    margin-left: 10px;
    span {  
      background: linear-gradient(#0002E9, #FF00C7);
      background-size: 400% 400%;
	    animation: gradient .3s ease infinite;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .green {
      background: #00FA9A;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@media (max-width: 500px) {
  .title-logo {
    h1 {
      font-size: 50px;
    }
  }
}



.title-block {
  
  &-img img {
    position: absolute;
  top: -35px;
  left: 120px;
    width: 30px;
    height: 30px;
  }
}

.title-list {
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  &-img {
    margin-top: 7px;
    margin-bottom: 7px;
    // align-items: center;
    color: #fff;
    font-size: 20px;
    font-family: 'Montserrat';
    text-decoration: none;
    text-transform: uppercase;
    svg {
      margin-left: 10px;
    }
    img {
      margin-right: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 500px) {
  .title-list {
    margin-top: 30px;
  }
}

