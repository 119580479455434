.footer {
    position: absolute;
    bottom: 0;
    -webkit-box-shadow: 0px -8px 27px 6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px -8px 27px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0px -8px 27px 6px rgba(0, 0, 0, 0.2);       
    min-height: 83px;
    height: 83px;
    width: 100%;
    background-color: rgb(18, 6, 68);
    .container {
        height: 100%;
    }
    &-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-end;
        a {
            margin-left: 5px;
            margin-right: 5px;
        }
        a > img {
            height: 30px;
            width: 30px;
            color: white;
            fill: white;
        }
    }
}

@media (max-width: 500px) {
    .footer-container {
        justify-content: center;
    }
}
